/* IMPORTS */
/* ############################### */
@font-face {
  font-family: 'Futura-LT';
  src: url('../../public/fonts/Futura-Light-webfont.eot'); /* IE9 Compat Modes */
  src: url('../../public/fonts/Futura-Light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/fonts/Futura-Light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/fonts/Futura-Light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../public/fonts/Futura-Light-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../public/fonts/Futura-Light-webfont.svg#Futura-LT') format('svg'); /* Legacy iOS */
}
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:400,700');

/* BASE CSS */
/* ############################### */
/* OVERRIDES */
html, body {
  font-size: 90%;
  line-height: 1.2;
  color: rgba(0,0,0,0.87);
  margin: 0;
}
#root {
  overflow: hidden;
}
*:focus {
  outline: none;
}
p.MuiTypography-body1 {
  margin: 1.5rem 0;
}

/* ALIGNMENTS */
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.centered {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.right-aligned {
  display: block;
  margin-right: 0;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.navpoint {
  margin-top: -13vh;
  position: absolute;
}

/* SIZING */
.xsmall {
  width: 15%;
}
.small {
  width: 25%;
}
.medium {
  width: 50%;
}

/* CUSTOM CLASSES */
.alert {
  margin-right: 0.5rem;
  margin-top: 1rem;
}

/* RIBBONS */
.incut-ribbon {
  font-weight: bold;
  color: #fff;
  padding: 0.5rem;
}
.incut-ribbon {
  --r: 1rem; /* control the cutout */
  
  border-block: .5rem solid #0000;
  padding-inline: 1rem calc(var(--r) + 0.5rem);
  line-height: 1.8;
  clip-path: polygon(100% 0,0 0,0 100%,100% 100%,calc(100% - var(--r)) calc(100% - .25rem),100% 50%,calc(100% - var(--r)) .25rem);
  background:
   radial-gradient(.4rem 50% at left,#000a,#0000) border-box,
   rgb(219, 40, 40) padding-box; /* the color  */
  width: fit-content;
}

/* NAVIGATION */
/* NORMAL NAVIGATION BAR */
.NavFloat {
  background-color: rgba(0,0,0,0.9);
  padding: 2rem;
}
.MobileLogo,
.NavMobile,
.NavMobile .mobile,
.NavBar .mobile,
.NavFloat .mobile {
  display: none !important;
}
.NavBar .logo, .NavFloat .logo {
  width: auto;
  height: 5.5vh;
}
.NavBar	.MuiButton-root, .NavFloat .MuiButton-root {
  line-height: 1;
}
.NavBar .dropdown .MuiMenuItem-root, .NavFloat .dropdown .MuiMenuItem-root {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.78571429em 1.14285714em !important;
  line-height: 1.2;
}
.NavBox {
  display: none;
}

/* TOP ALERTS */
.Alert {
  padding: 1rem;
  box-shadow: inset 0 -1px 2px rgba(0,0,0,0.5);
}
.Alert a:link,
.Alert a:active,
.Alert a:visited {
  color: rgba(255,255,255,0.87);
}
.Alert a:hover {
  color: rgba(255,255,255,0.5);
}
.Alert .mobile-logo {
  display: none;
}
.Alert .right {
  font-size: 1.5rem;
  text-align: right;
}
.Alert .left {
  font-size: 1.5rem;
  text-align: left;
}
.Alert .material-icons {
  font-size: 1.5rem;
  line-height: 1.2;
  padding: 0 0.5rem 0 0;
}

/* CARDS */
.Card.MuiPaper-root {
  display: flex;
  height: 100%;
  flex-flow: column;
}
.Card .MuiCardContent-root {
  flex-grow: 1;
}
/* BANNER */
.Banner {
  overflow: hidden;
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}
.Banner.picture {
  background-position: center;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-attachment: fixed !important;
}
.Banner .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  vertical-align: middle;
  display: flex;
}
.Banner .content {
  margin: auto;
  top: -3.7vh;
  position: relative;
}
.Banner .content img {
  width: auto;
  height: 5vh;
}
.Banner.video .overlay {
  background-image: url('../../public/images/overlay.png');
  background-color: rgba(0,0,0,0.2);
}
.MobileLogo.overlay {
  background-image: url('../../public/images/overlay.png');
}
.Banner video {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  object-fit: cover;
}
.Banner .BannerTitle {
  padding: 2.5em 0;
  background-color: rgba(30,64,92,.9);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 7%;
  min-height: 3vh;
  max-height: 8vh;
  display: flex;
}
.Banner .BannerTitle .MuiContainer-root {
  align-self: center;
}
.Banner .BannerTitle img {
  width: auto;
  height: 100%;
  float: left;
  padding-right: 1.5rem;
  max-height: 4.5vh;
}
.Banner .banner__subtitle {
  color: rgba(255,255,255,.8);
}
.Banner .Button {
  margin: 1.5em 0;
  font-size: 1.75rem;
  text-transform: none;
  border: 2px solid white;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
  line-height: 1em;
  padding: 0.78em 1.5em 0.78em;
}
.Banner .Button:hover {
  color: rgba(0,0,0,0.8);
  background: white;
  border-width: 2px;
}.Banner img
.Banner .msgold {
  margin: 0 1.5em 0 0;
  padding: 0.25em 0 0 0;
}
/* MAIN CONTENT */
.Main {
  padding: 3.6rem 0;
}
.Main .content .MuiGrid-root img {
  width: 5rem;
}
.Main .content img.centered {
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
}
/* SIDEBAR */
.sidebar {
  border-left: 1px solid rgba(34,36,38,.15);
  padding: 0 0 0 2.5rem;
  position: static;
}
.sidebar a:active,
.sidebar a:visited,
.sidebar a:link {
  color: rgba(0,0,0,0.87);
  width: 100%;
  line-height: 1.5;
}
.sidebar a:hover {
  color: rgba(30,64,92,.9);
}
.sidebar .MuiButton-root {
  padding: 0;
  justify-content: left;
  text-transform: inherit;
  font-size: inherit;
  color: rgba(0,0,0,0.87);
}
.sidebar .MuiPaper-root {
  padding: 0 !important;
  background: none !important;
  border: 0;
  box-shadow: none;
  margin-top: 8px !important;
}
.sidebar .MuiAccordionSummary-root {
  padding: 0;
}
.sidebar .MuiAccordionSummary-content {
  margin: 0;
}
.sidebar .MuiAccordionDetails-root {
  font-size: .85714286em !important;
  color: rgba(0,0,0,.5);
  padding: 0;
}
.sidebar img {
  width: 100%;
}

/* BLOG */
.Blog.Section img {
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
}
.Blog .MuiChip-root {
  padding: 1.5rem 1rem;
  line-height: 1.2;
  font-size: 1rem;
  margin: 0;
  top: -1.5rem;
  position: relative;
  left: -1.5rem;
  border-radius: 0 0 0.5rem 0;
}
.Blog .Post .MuiChip-root {
  padding: 1.5rem 1rem;
  line-height: 1.2;
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  position: relative;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
}
.Blog .Post a:link.MuiChip-root,
.Blog .Post a:active.MuiChip-root,
.Blog .Post a:visited.MuiChip-root {
  color: rgba(255,255,255,0.9);
}
.Blog .Post a:hover.MuiChip-root {
  background-color: #51001d;
}
.Blog .MuiCardActions-root {
  padding: 0;
}
.Blog .MuiButton-root {
  text-transform: none;
  font-weight: bold;
  background-color: #e0e1e2;
  border: none;
  border-radius: 0 0 0.25em 0.25em;
  padding: 1rem;
}
.Blog .MuiButton-root:hover {
  background-color: #cacbcd;
  border: none;
}
.Blog a:link,
.Blog a:active,
.Blog a:visited {
  color: rgba(0,0,0,0.67);
}
.Blog .MuiCard-root {
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
}
.Blog .MuiCardContent-root {
  height: 100%;
  position: relative;
  padding: 1.5rem;
  border-top: 1px solid rgba(0,0,0,0.15);
}
.Blog .postfade {
  height: 5vw;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,1));
  left: 0;
  bottom: 0;
  z-index: 0;
}
.Blog .Breadcrumbs {
  margin-bottom: 1rem;
}
/* BOXES */
.Section {
  position: relative;
  padding: 3.5rem 0;
}
.Section .content {
  padding: 0 3.5rem 0 0;
}
.Section .sidebar {
  padding: 0 0 0 3.5rem;
}
.Section .closing-image {
  display: block;
  right: -3.5rem;
  bottom: -3.3%;
  width: 100%;
  position: relative;
  z-index: -10;
  max-width: 100vw;
  max-height: 100vh;
  margin-top: -6%;
}
.Section.About.Main .content .MuiGrid-item .material-icons {
  font-size: 5.5rem !important;
}
/* SUBFOOTER */
.SubFooter {
  padding: 3.6rem 0;
}
.SubFooter .clients img {
  height: 5em;
  min-width: 8vw;
}
/* FORMS */
.ContactForm input {
 margin: auto;
}
.ContactForm .css-fjkvgf-MuiInputBase-root-MuiFilledInput-root {
  background-color: rgba(255,255,255,0.87);
}
.ContactForm .MuiFormControl-marginDense {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

/* FOOTER */
.Footer a:link,
.Footer a:active,
.Footer a:visited {
  color: rgba(255,255,255,0.87);
}
.Footer a:hover {
  color: rgba(255,255,255,0.5);
}
.Footer .MuiTypography-root {
  font-size: 1.25rem;
}

/* QHD to UHD CSS */
/* ############################### */
@media only screen and (min-width: 2300px) and (max-width: 3000px) {
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 6.5rem !important;
  }
  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -3.5rem 0 -63%;
  }
  /* BOXES */
  .Section.SharePoint.Main .content img.centered {
    margin-top: -7.5vh;
  }
}
/* DESKTOP to QHD CSS */
/* ############################### */
@media only screen and (min-width: 1935px) and (max-width: 2299px) {
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 6rem !important;
  }
  /* FOOTER */
  .Footer .copyright .MuiTypography-root {
    font-size: 0.91rem !important;
  }
}
/* LAPTOP to DESKTOP CSS */
/* ############################### */
@media only screen and (min-width: 1366px) and (max-width: 1934px) {
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 5.5rem !important;
  }
  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -3.5rem 0 -100%;
  }
  /* NAVIGATIONS */
  .NavBar .MuiButton-root, .NavFloat .MuiButton-root {
    font-size: 1rem;
  }
  .NavBar .dropdown .MuiMenuItem-root, .NavFloat .dropdown .MuiMenuItem-root {
    font-size: 1rem;
  }
  /* TOP ALERTS */
  .Alert .right {
    font-size: 1.2rem;
    text-align: right;
  }
  .Alert .left {
    font-size: 1.2rem;
    text-align: left;
  }
  .Alert .material-icons {
    font-size: 1rem;
    padding: 0 0.5rem 0 0;
  }
  /* BANNER */
  .Banner .content {
    top: 3vw;
  }
  .Banner .content img {
    height: 4.5vw;
  }
  /* BOXES */
  .Section {
    padding: 3.5rem 0;
  }
  .Section.SharePoint.Main .content img.centered {
    margin-top: -10vh;
  }  
  /* INTRO */
  .Intro {
    padding: 3.25rem 0 2.5rem;
  }
  .Intro .material-icons {
    font-size: 3.75rem !important;
    padding: 2rem;
    border: 0.5rem rgba(0,0,0,.1);
    box-shadow: inset 0 0 0 0.5rem rgba(0,0,0,.1);
  }
  /* SUBFOOTER */
  .SubFooter {
    padding: 3.25rem 0 2.5rem;
  }
}
/* TABLET to LAPTOP CSS */
/* ############################### */
@media only screen and (min-width: 744px) and (max-width: 1365px) {
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 5.5rem !important;
  }
  .OfficeSuite img {
    width: 30% !important;
  }
  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -27vw 0;
  }
  .Section.SharePoint.Main .content img.centered {
    margin-top: -7vh;
  }
  /* NAVIGATION */
  .NavBox {
    height: 75px;
    display: block;
  }
  .NavMobile {
    background-color: #333 !important;
    color: white !important;
  }
  .NavMobile .MuiButtonBase-root {
    padding: 0.75em 0.75em 0.75em 1em;
  }
  .NavMobile .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }
  .NavMobile .MuiTypography-h1  {
    font-size: 2rem;
    margin-left: 0.5em;
  }
  .drawer .MuiPaper-root {
    overflow: visible;
  }
  .drawer .MuiSvgIcon-root {
    font-size: 2.2rem !important;
    position: absolute;
    right: 0.75em;
    top: 0.75em;
  }
  .drawer .remote {
    display: none;
  }
  .drawer .Menu {
    width: 100%;
    z-index: 100;
  }
  .drawer .Menu .MuiLink-root {
    width: 100%;
  }
  .drawer a:link, .drawer a:active, .drawer a:visited {
    color: rgba(0,0,0,0.87);
  }
  .drawer a:hover {
    color: rgba(0,0,0,1);
  }
  .MobileLogo {
    text-align: center;
    padding: 2.5rem;
  }
  .MobileLogo,
  .NavMobile,
  .NavMobile .mobile {
    display: block !important;
  }
  .NavBar,
  .NavBar .desktop,
  .NavFloat,
  .NavFloat .desktop,
  .NavMobile .desktop {
    display: none !important;
  }
  /* NAV LOGO */
  .MobileLogo {
    display: block;
    text-align: center;
  }
  .MobileLogo .logo {
    max-height: 8vw;
    width: auto;
  }
  /* TOP ALERTS */
  .Alert {
    padding: 1.5rem;
  }
  .Alert .right {
    font-size: 1.2rem;
    text-align: center;
  }
  .Alert .left {
    font-size: 1.2rem;
    text-align: center;
  }
  .Alert .material-icons {
    font-size: 1.55rem;
    padding: 0 0.5rem 0 0;
  }
  /* BANNER */
  .Banner.video {
    height: calc(100vw - 10vh);
    max-height: 90vh;
    min-height: 45vh;
  }
  .Banner .BannerTitle {
    padding: 2.5rem 0;
    height: 4.7vh;
  }
  .Banner .BannerTitle img {
    max-height: 7.5vh;
  }
  .Banner.plain {
    height: 0;
  }
  /* MAIN CONTAINER */
  .Main .content img.centered {
    width: 100%;
    height: auto;
  }
  /* BOXES */
  .Section {
    padding: 3.5rem 0 !important;
  }
  .Section .placeholder {
    min-height: 66.83vw;
  }
  .bg-down {
    background-position: center 125% !important;
    background-size: 100% 66% !important;
  }
  .bg-up {
    background-position: center top !important;
    background-size: 100% 58% !important;
  }
  .Section .closing-image {
    right: -0;
    bottom: -7vw;
    width: 100vw;
  }
  .Section .content {
    padding: 0;
  }
  .Section .sidebar {
    display: none;
  }
  .Section.Blog .sidebar {
    display: block;
    padding: 2.5rem;
    border-left: 0;
    margin-top: 2.5rem;
    background-color: #EAEAEA;
    border-radius: 1rem;
  }
  .Section.Blog .sidebar img {
    width: 50%;
  }
  /* INTRO */
  .Intro {
    padding: 3.5em 0 1.5em;
  }
  .Intro .material-icons {
    padding: 2.5rem;
    border: 1rem rgba(0,0,0,.1);
    box-shadow: inset 0 0 0 1rem rgba(0,0,0,.1)
  }
  /* SUBFOOTER */
  .SubFooter {
    padding: 2.5rem;
  }
  .SubFooter .clients img {
    height: 6.25vh;
    padding-bottom: 2em;
    width: auto;
  }
  .SubFooter .ClientSuccess img {
    max-width: 35vw;
    max-height: 15vh;
    width: 100%;
    height: auto;
  }
  .Footer .MuiGrid2-root {
    text-align: center;
  }
}
/* MOBILE to TABLET CSS */
/* ############################### */
@media only screen and (min-width: 0px) and (max-width: 743px) {
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 3rem !important;
  }
  .OfficeSuite img {
    width: 30% !important;
  }
  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -20vw 0;
  }
  .Section.SharePoint.Main .content img.centered {
    margin-top: -5vh;
  }
  /* NAVIGATION */
  .NavBox {
    height: 75px;
    display: block;
  }
  .NavMobile {
    background-color: #333 !important;
    color: white !important;
  }
  .NavMobile .MuiButtonBase-root {
    padding: 0.75em 0.75em 0.75em 1em;
  }
  .NavMobile .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }
  .NavMobile .MuiTypography-h1  {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
  .drawer .MuiPaper-root.MuiDrawer-paper {
    background-color: transparent;
    overflow: visible;
  }
  .drawer .MuiSvgIcon-root {
    font-size: 1.66rem !important;
    position: absolute;
    right: 0.75em;
    top: 0.75em;
  }
  .drawer .remote {
    display: none;
  }
  .drawer .Menu {
    width: 100%;
    z-index: 100;
  }
  .drawer .Menu .MuiLink-root {
    width: 100%;
  }
  .drawer .Menu a:active,
  .drawer .Menu a:visited,
  .drawer .Menu a:link {
    color: rgba(0,0,0,0.87);
    padding: 1rem;
  }
  .MobileLogo {
    text-align: center;
    padding: 2.5rem;
  }
  .MobileLogo,
  .NavMobile,
  .NavMobile .mobile {
    display: block !important;
  }
  .NavBar,
  .NavBar .desktop,
  .NavFloat,
  .NavFloat .desktop {
    display: none !important;
  }
  /* NAV LOGO */
  .MobileLogo {
    display: block !important;
    text-align: center;
  }
  .MobileLogo .logo {
    max-height: 15vw;
    width: auto;
  }
  .logo-img {
    max-width: 50vw !important;
  }

  /* TOP ALERT */
  .Alert {
    text-align: center;
  }
  .Alert .right {
    font-size: 1rem;
    text-align: center;
    padding-top: 0.5em;
  }
  .Alert .left {
    font-size: 1rem;
    text-align: center;
    padding-top: 0.5em;
  }
  .Alert .material-icons {
    font-size: 1rem;
    padding: 0 0.5rem 0 0;
  }
  /* BANNER */
  .Banner.video {
    height: calc(100vw - 4vh);
    max-height: 95vh;
    min-height: 45vh;
  }
  .Banner .Button {
    margin: 1rem;
    font-size: 1rem;
  }
  .Banner .BannerTitle {
    padding: 1rem 0;
    height: 9.4vh;
  }
  /* BOXES */
  .Section {
    padding: 1.5rem !important;
  }
  .Section .placeholder {
    min-height: 115vw;
  }
  .bg-down {
    background-position: center 120% !important;
    background-size: 100% 53% !important;
  }
  .bg-up {
    background-position: center top !important;
    background-size: 100% 45% !important;
  }
  .Section .closing-image {
    right: 0;
    bottom: -7vw;
    width: 100vw;
  }
  .Section .content {
    padding: 0;
  }
  .Section .sidebar {
    display: none;
  }

  /* INTRO */
  .Intro {
    padding: 3.5em 0 1.5em;
  }
  .Intro .material-icons {
    font-size: 3rem !important; 
    padding: 1.25rem;
    border: 0.5rem rgba(0,0,0,.1);
    box-shadow: inset 0 0 0 0.5rem rgba(0,0,0,.1)
  }
  /* SUBFOOTER */
  .SubFooter {
    padding: 1.5rem;
  }
  .SubFooter .clients img {
    height: 6.25vh;
    padding-bottom: 2em;
    width: auto;
  }
  .SubFooter .ClientSuccess img {
    max-width: 45vw;
    max-height: 15vh;
    width: 100%;
    height: auto;
  }
  /* FOOTER */
  .Footer .MuiGrid2-root {
    text-align: center;
  }
  .Footer .MuiTypography-root {
    font-size: 1rem;
  }
}